import { useState } from 'react';
import PdfViewer from './PdfViewer';
import {Route, Routes} from "react-router-dom";
import Login from './Login';
import './App.css';
import GreetingsPage from './Pages/GreetingsPage';
import ViewPage from './Pages/ViewPage';
import DocumentsPage from './Pages/DocumentsPage';
import OneDoc from './Pages/OneDoc';
import SignedDocPage from './Pages/SignedDocPage';
import GetEsiaData from './Pages/GetEsiaData';
import SignProcessPage from './Pages/SignProcessPage';

function App() {

  return (
    <Routes>
      {/* <Route path='/' element={<Main/>}/> */}
      <Route path='/' element={<GreetingsPage/>}/>
      <Route path='/view' element={<ViewPage/>}/>
      <Route path='/esia' element={<GetEsiaData/>}/>
      <Route path='/doc' element={<DocumentsPage/>}/>
      <Route path='/doc/:id' element={<OneDoc/>}/>
      <Route path='/signdocs' element={<SignProcessPage/>}/>
      <Route path='/signed' element={<SignedDocPage/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/pdf' element={<PdfViewer />}/>
    </Routes>
  
  );
}

export default App;
