import React, { useEffect, useState } from "react";
import "./style/documents.css";
import CheckBox from "../Components/CheckBox";
import { Button, Modal } from "react-bootstrap";
import MyModal from "../Components/MyModal";
import { useNavigate } from "react-router-dom";
import { getDocumentsForSign2 } from "../functions/function";
import { ServerApi } from "../api/ServerApi";
import { useCookies } from "react-cookie";
import { esiaDataTest } from "../esiatest";
import { getSignedDocuments, encrypt, decrypt } from "../functions/function";
import refreshIcon from "../resourse/icons/refreshColor.svg";
import { decode as base64_decode, encode as base64_encode } from "base-64";

//TODO Сделать лоадер загрузки getDocumentsForSign2

export default function DocumentsPage() {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const serverApi = new ServerApi();
  const [cookies, setCookie] = useCookies([
    "operator_id",
    "user_id",
    "esia_url",
    "esia_token",
    "link_token",
  ]);
  const [documentsForSign, setDocumentsForSign] = useState([]);
  const [previewImagesState, setPreviewState] = useState([]);
  const [userName, setUserName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [disDataModal, setDisDataModal] = useState(false)
  const [disData, setDisData] = useState([])
  const [error , setError] = useState('');
  const handleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);
    setIsDisabled(!isDisabled);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Запрос на отправку кода для ввода в MyModal.jsx
  const getCodeForSign = () => {
    const user_id = localStorage.getItem("user_id");
    const operator_id = localStorage.getItem("operator_id");
    setIsModalOpen(true);
    const body = {
      action: "getCodeForSign",
      data: {
        user_id: user_id,
        operator_id: operator_id,
      },
    };
    serverApi.sendPostRequest(body).then((response) => {
      console.log(response);
    });
  };

  const disabledData = () => {
    let disableData = localStorage.getItem('disableData');
    if (disableData) {
      disableData = JSON.parse(disableData);
      console.log(disableData)
      if(disableData.length > 0) {
        setDisData(disableData)
        setDisDataModal(true)
      }
    }
  }

  useEffect(() => {
    const encodedData = localStorage.getItem("esiaDataEncrypt");
  
    if (encodedData) {
      const esiaData = JSON.parse(decrypt(encodedData));
      const user_id = localStorage.getItem("user_id");
      const operator_id = localStorage.getItem("operator_id");
      serverApi.getUserToken(user_id, operator_id).then((response) => {
        if (response.status === 200) {
          // const esiaData = JSON.parse(localStorage.getItem('esiaData'));
          const personInfo = esiaData.personInfo;
          const fullName = `${personInfo.lastName} ${personInfo.firstName} ${personInfo.middleName}`;
          document.querySelector(".header").style.opacity = 1;
          setUserName(fullName);
          setModalOpen(true);
          //Формирование документов на подпись. Подстановка данных из ЕСИА
          getDocumentsForSign2(esiaData).then((response) => {
            setModalOpen(false);
            // console.log(response);

            if (response.data) {
              // console.log(response.data);
              setError(response.data.error)
              const documents = localStorage.getItem("documents");
              if (documents) {
                if (documents.length > 0) {
                  const docsList = JSON.parse(documents);
                  document
                    .querySelector(".doc-check")
                    .classList.remove("d-none");
                  setDocumentsForSign(docsList);
                  docsList.forEach((doc) => {
                    const doc_id = doc.upload_id;
                    const images = {
                      upload_id: doc_id,
                      images: doc.preview_pages,
                    };
                    setPreviewState((prevState) => [...prevState, images]);
                  });
                } else {
                  document
                    .getElementById("emptyDocList")
                    .classList.remove("d-none");
                }
              }
            } else {
              document.querySelector(".doc-check").classList.remove("d-none");
              setDocumentsForSign(response);
              response.forEach((doc) => {
                const doc_id = doc.upload_id;
                const images = { upload_id: doc_id, images: doc.preview_pages };
                setPreviewState((prevState) => [...prevState, images]);
              });
            }
            disabledData()
          });
        }
      });

      // const encodedData = localStorage.getItem("esiaDataEncrypt");
      // console.log(encodedData);
      // console.log(JSON.parse(decrypt(encodedData)));
      // localStorage.setItem("esiaDataEncoded", encodedData);
      // console.log(encodedData);
    } else {
      navigate("/");
    }
  }, []);
  const [timer, setTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  const click = () => {
    setIsModalOpen(false);
    setTimer(true);
    setTimeLeft(60);
  };

  useEffect(() => {
    if (timer) {
      const timerId = setInterval(() => {
        setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        
        // Проверяем, достигли ли мы нуля
        if (timeLeft === 0) {
          setTimer(false);
          setTimeLeft(60);
        }
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timer, timeLeft]);
  //JPEG для предпросмотра документов
  useEffect(() => {
    localStorage.setItem("doc", JSON.stringify(previewImagesState));
  }, [previewImagesState]);

  return (
    <>
      <div id="doc-page">
        <div className="fixed-top">
          <div className="header">
            <h1 className="header-title">{userName}</h1>
            <img src="./icons/gos.png" className="logo-gos" />
          </div>
        </div>
          <div className="doc-block" style={{ marginTop:'100px'}}>
          {documentsForSign.length > 0 ? 
            <div className="doc-subtitle-block" >
              <p className="doc-subtitle">Ожидают вашей подписи</p>
              <p className="doc-subtitle">{documentsForSign.length}</p>
            </div>
            :
            ''}
            {error != '' ? 
            <div id="emptyDocList" >
              Нет документов на подпись
              Возможные ошибки:
              {error && error.map((e)=> {
                return <p key={e}>{e}<br/></p>
              })}
              <p>Для решения данной проблемы пройдите по ссылке из смс заново!</p>
            </div>
            :''
          } 
            <div className="all-doc">
              {documentsForSign.map((e) => {
                return (
                  <div
                    key={e.upload_id}
                    onClick={() => navigate(`/doc/${e.upload_id}`)}
                  >
                    <div className="doc-item">
                      <div>
                        <div className="doc-name">{e.name}</div>
                        <div className="doc-date">{e.date}</div>
                      </div>
                      <div style={{ width: "24px", height: "24px" }}>
                        <img
                          src="./icons/Arrow.svg"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                    </div>
                    <hr style={{ margin: "0px" }} />
                  </div>
                );
              })}
            </div>
            <div className="doc-check d-none">
              <CheckBox
                label="Я проверил подписываемые документы и подтверждаю ознакомление"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>

            <div
              className=""
              style={{
                display: "flex",
                width: "inherit",
                justifyContent: "center",
              }}
            >
            {documentsForSign.length > 0 ?
            <>
            {timer ? 
              <button
                className="doc-btn"
                style={{
                  backgroundColor: "rgb(56 141 168 / 28%)",
                  color:  "#666",
                  cursor: "not-allowed",
                }}
              >
                Повторно запросить смс можно через {timeLeft.toString().padStart(2, '0')}
              </button>
              :
              <button
                className="doc-btn"
                onClick={getCodeForSign}
                style={{
                  backgroundColor: isDisabled
                    ? "rgb(56 141 168 / 28%)"
                    : "#388DA8",
                  color: isDisabled ? "#666" : "#fff",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
                disabled={!isChecked}
              >
                Продолжить
              </button>
            }
            </>
            : ''}
              <MyModal
                click={click}
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                backdrop="static"
                keyboard={false}
              />
            </div>
          </div>
      </div>

      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 style={{ color: "white" }}>Загрузка</h4>
        </Modal.Title>
        <Modal.Body>
          <div className="loader-container">
            <img
              className="objRotate mr-5 loading"
              id={"balance-loading"}
              src={refreshIcon}
              alt={"refreshIcon"}
              width="50px"
            />
            <h5 className="loader-text">Пожалуйста, подождите</h5>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={disDataModal}
        onHide={() => setModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title id="contained-modal-title-vcenter" className="centerDiv mt-3">
          <h4>Внимание!</h4>
        </Modal.Title>
        <Modal.Body>
          <div className="loader-container disData-container mt-1">
            <div className="loader-text">Не удалось получить данные</div>
            {disData.map((item) => {
              return (
                <li className="loader-text mt-1">{item}</li>
              )
            })}
            <div className="disData-foot">Эта информация будет отсутствовать в договоре</div>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="disDataBtn centerDiv" onClick={() => setDisDataModal(false)}>
            Продолжить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
