import { ServerApi } from "../api/ServerApi";
import axios from "axios";
import CryptoJS from "crypto-js";

//Можно(даже нужно) объединить с getEsiaData
export async function setEsiaData(esia_code) {
  window.history.pushState({}, document.title, window.location.pathname);
  return getEsiaData(esia_code).then((response) => {
    if (response) {
      localStorage.setItem("esiaAuth", true);
      localStorage.setItem("esiaData", JSON.stringify(response));
      localStorage.setItem(
        "esiaDataEncrypt",
        encrypt(JSON.stringify(response))
      );

      // console.log(response);
      return response.token_data;
    }
  });
}

//Получение данных ЕСИА по коду
export function getEsiaData(esia_code) {
  return new Promise(function (resolve, reject) {
    fetch(`https://esia.idgos.ru:8788/response.php?code=${esia_code}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.data) {
          const esia_data = data.data;
          resolve(esia_data);
        } else {
          resolve(undefined);
        }
      });
  });
}

export function encrypt(txt) {
  const _key = "secret";
  return CryptoJS.AES.encrypt(txt, _key).toString();
}

export function decrypt(txtToDecrrypt) {
  const _key = "secret";
  return CryptoJS.AES.decrypt(txtToDecrrypt, _key).toString(CryptoJS.enc.Utf8);
}

//Лишняя. Но надо проверить
export async function getDocumentsForSign(operator_id, customer_id) {
  const loader = document.getElementById("loader");
  loader.classList.remove("display-none");
  const serverApi = new ServerApi();
  const body = JSON.stringify({
    action: "getDocumentsForSign",
    data: { operator_id: operator_id, customer_id: customer_id },
  });
  return serverApi.getDocumentsForSign(body).then((response) => {
    if (response.success) {
      const doc_data = response.data;
      localStorage.setItem("documents", JSON.stringify(doc_data));
      if (doc_data.length > 0) {
        document.getElementById("for-sign").classList.remove("display-none");

        // document.getElementById('sign-btn').classList.remove('display-none')
        return doc_data;
      }
    }
    //TODO
    loader.classList.add("display-none");
  });
}

//Подписанные документы
export async function getSignedDocuments(operator_id, user_id) {
  const serverApi = new ServerApi();
  const body = {
    action: "getSignedDocuments",
    data: { operator_id: operator_id, user_id: user_id },
  };
  return serverApi.sendPostRequest(body).then((response) => {
    console.log(response);
    if (response.status == 200) {
      const doc_data = response.data.documents;
      localStorage.setItem("signed", JSON.stringify(doc_data));
      if (doc_data.length > 0) {
        return doc_data;
      }
    }
  });
}

//Формирование документов на подпись. Формирование файлов предпросмотра
export async function getDocumentsForSign2(body) {
  let disableData = [];
  const personInfo = body.personInfo;
  const addressInfo = body.addressInfo[0];
  const documentInfo = body.documentInfo[0];
  if(!personInfo) disableData.push('Персональные данные')
  if(!addressInfo) disableData.push('Адрес прописки и места жительства')
  if(!documentInfo) disableData.push('Паспортные данные')
  localStorage.setItem('disableData', JSON.stringify(disableData))

  const address_house = (addressInfo && addressInfo.house) ? `, ${addressInfo.house}` : "";
  const address_flat = (addressInfo && addressInfo.flat) ? `, ${addressInfo.flat}` : "";

  const addressStr = (addressInfo && addressInfo.addressStr) ? addressInfo.addressStr : "";
  const addressCity = (addressInfo && addressInfo.city) ? addressInfo.city : "";
  const addressStreet = (addressInfo && addressInfo.street) ? addressInfo.street : "";
  const addressHouse = (addressInfo && addressInfo.house) ? addressInfo.house : "";
  const addressFlat = (addressInfo && addressInfo.flat) ? addressInfo.flat : "";
  const addressZipCode = (addressInfo && addressInfo.zipCode) ? addressInfo.zipCode : "";

  const user_id = localStorage.getItem("user_id");
  const customer_id = localStorage.getItem("operator_id");
  const fullName = `${personInfo.lastName} ${personInfo.firstName} ${personInfo.middleName}`;
  const shortName = `${personInfo.lastName} ${personInfo.firstName.substr(
    0,
    1
  )}. ${personInfo.middleName.substr(0, 1)}.`;
  const half_addr_arr = [];
  let half_addr = "";

  if (addressInfo && addressInfo.street) half_addr_arr.push(addressInfo.street);
  if (addressInfo && addressInfo.house) half_addr_arr.push(addressInfo.house);
  if (addressInfo && addressInfo.flat) half_addr_arr.push(addressInfo.flat);
  half_addr = half_addr_arr.join(", ");
  const sendData = {
    action: "getDocumentsForSign2",
    data: {
      user_id: user_id,
      customer_id: customer_id,
      esia: {
        firstname_s: personInfo.firstName,
        middlename_s: personInfo.middleName,
        lastname_s: personInfo.lastName,
        full_name: fullName,
        short_name: shortName,
        birth_date: personInfo.birthDate,
        address_info: `${addressStr}${address_house}${address_flat}`,
        half_addr: half_addr,
        address_city: addressCity,
        address_street: addressStreet,
        address_house: addressHouse,
        address_flat: addressFlat,
        address_code: addressZipCode,
        passport_series: documentInfo.series,
        passport_number: documentInfo.number,
        passport_division_code: documentInfo.issueId,
        passport_division_date: documentInfo.issueDate,
        passport_division_by: documentInfo.issuedBy,
      },
    },
  };
  const serverApi = new ServerApi();
  return serverApi.getDocumentsForSign2(sendData).then((response) => {
    // console.log(response);
    if (response.status == 200 && response.data.success) {
      const doc_data = response.data.documents;
      console.log(doc_data);
      localStorage.setItem("documents", JSON.stringify(doc_data));
      return doc_data;
    } else return response;
  });
}
