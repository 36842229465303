import { useEffect } from "react";
import { setEsiaData } from "../functions/function";
import { useSearchParams } from "react-router-dom";
import { ServerApi } from "../api/ServerApi";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
// import CryptoJS from "crypto-js";

export default function GetEsiaData() {
  const [searchParams, setSearchParams] = useSearchParams();
  const esiaCode = searchParams.get("code");
  const esiaError = searchParams.get("error");
  const serverApi = new ServerApi();
  const [cookies, setCookie] = useCookies([
    "operator_id",
    "user_id",
    "esia_url",
    "esia_token",
    "link_token",
  ]);
  const navigate = useNavigate();

  // function encrypt(txt) {
  //   const _key = "secret";
  //   return CryptoJS.AES.encrypt(txt, _key).toString();
  // }
  
  // function decrypt(txtToDecrrypt) {
  //   const _key = "secret";
  //   return CryptoJS.AES.decrypt(txtToDecrrypt, _key).toString(CryptoJS.enc.Utf8);
  // }

  // const testEsiaData = '{"token_data":{"token":"eyJ2ZXIiOjEsInR5cCI6IkpXVCIsInNidCI6ImFjY2VzcyIsImFsZyI6IlJTMjU2In0.eyJuYmYiOjE3MzEzOTU5NjcsInNjb3BlIjoiYmlydGhkYXRlP29pZD0xMTM5Njg2OTc5IHVzcl9vcmc_b2lkPTExMzk2ODY5NzkgYWRkcmVzc2VzP29pZD0xMTM5Njg2OTc5IGlkX2RvYz9vaWQ9MTEzOTY4Njk3OSBmdWxsbmFtZT9vaWQ9MTEzOTY4Njk3OSBjaXRpemVuc2hpcD9vaWQ9MTEzOTY4Njk3OSIsImlzcyI6Imh0dHA6XC9cL2VzaWEuZ29zdXNsdWdpLnJ1XC8iLCJ1cm46ZXNpYTpzaWQiOiJkN2ZmZjE5Yi03YTI3LWEzNjgtMThjZi1iMzZkYzc4MmM5NzIiLCJ1cm46ZXNpYTpzYmpfaWQiOjExMzk2ODY5NzksImV4cCI6MTczMTQwNjc2NywiaWF0IjoxNzMxMzk1OTY3LCJjbGllbnRfaWQiOiJJTlRST05FWCJ9.BAOmHZ-wDsHCzevBzIQkql-86QjOOj_QGOLUrMMDVByC0u3QDzKemzZQ5VtFU-iUqcQbvQJ78zUwai0_z_YC0j7PbbY5FlNPJqEoFEXx10GZEFCd3u_s4uqKZHhiEZYIIMfCHwxtxU-inkeUiPQDD9bDLpfFJj_CuoUKhU1b8fWrLV3jsGqp3pYTc-soNse4WezpLGSrTd6nSJlipfqOqVq5g2cefuu7rZ6-Z9Bg3Qj7OOBHo8rVxzprcRF1YvjkY2mDnqc2HbJ-MRqgG5alZGNtM0ypNTN5dzdX0NUlmCLVop72OtqDsGOVeic3BwJb-EzvXH5qEkcmw2yNM0vKmg","timestamp":{"iat":1731395967,"exp":1731406767},"oid":1139686979,"refresh_token":"9310694c-1d73-46d8-b329-133adcafd3d3"},"personInfo":{"stateFacts":["EntityRoot"],"firstName":"Георгий","lastName":"Молчанов","middleName":"Григорьевич","birthDate":"10.04.1973","trusted":true,"citizenship":"RUS","updatedOn":1728981658,"rfgUOperatorCheck":false,"status":"REGISTERED","verifying":false,"rIdDoc":478084340,"containsUpCfmCode":false,"eTag":"C60010FE7A482F644C52F3C711FBA64EC0AE68C3"},"orgInfo":{"stateFacts":["hasSize"],"size":0,"elements":[]},"addressInfo":{"stateFacts":["hasSize"],"size":0,"eTag":"12FC07FD39DE15663BEE76A96CCBE026FC4A4AB4","elements":[]},"documentInfo":[{"stateFacts":["EntityRoot"],"id":478084340,"type":"RF_PASSPORT","vrfStu":"VERIFIED","series":"4623","number":"464877","issueDate":"25.01.2024","issueId":"500066","issuedBy":"ГУ МВД России по Московской области","eTag":"CDDDF036EC73A7F7BBEB24C13A7B199B13AB29B6"}]}'

  

  // useEffect(() => {
  //   localStorage.setItem("esiaAuth", true);
  //   localStorage.setItem("esiaData", testEsiaData);
  //   localStorage.setItem(
  //     "esiaDataEncrypt",
  //     encrypt(testEsiaData)
  //   );
  // }, [])

  // useEffect(() => {
  //   // if (esiaCode) {
  //     // setEsiaData(esiaCode).then((response) => {
  //       // const esia_token = response.token;
  //       // const esia_token_expire = response.timestamp.exp;
  //       // let expires = new Date(Number(esia_token_expire) * 1000);
  //       // console.log(expires);
  //       // setCookie('esia_token', esia_token, {path: 'https://app.idgos.ru/docs', expires: expires})
  //       // const user_id = localStorage.getItem("user_id");
  //       // const operator_id = localStorage.getItem("operator_id");
  //       // serverApi
  //       //   .setUserToken(user_id, operator_id, esia_token, esia_token_expire)
  //       //   .then((response) => {
  //           // if(response){
  //             const user_id = localStorage.getItem('user_id')
  //             const operator_id = localStorage.getItem('operator_id')
  //             const body = {
  //               action: 'checkNoSignedDocs',
  //               data: {
  //                 user_id: user_id,
  //                 operator_id: operator_id
  //               }
  //             }
  //             serverApi.sendPostRequest(body).then((response) => {
  //               console.log(response)
  //               if(response.status === 200) {
  //                 if(response.data.documents.length == 0) navigate('/signed')
  //               }
  //             })
  //           // }
  //           navigate("/doc");
  //         // });
  //     // });
  //   // }
  //   // if (esiaError) {
  //   //   alert('К сожалению, возникла проблема при попытке загрузить данные. Пожалуйста, попробуйте повторно перейти по ссылке, которую мы отправили вам ранее на телефон.');
  //   //     navigate('/');
  //   // }
  // }, []);

  //Получение данных от ЕСИА.
  useEffect(() => {
    if (esiaCode) {
      setEsiaData(esiaCode).then((response) => {
        const esia_token = response.token;
        const esia_token_expire = response.timestamp.exp;
        let expires = new Date(Number(esia_token_expire) * 1000);
        console.log(expires);
        // setCookie('esia_token', esia_token, {path: 'https://app.idgos.ru/docs', expires: expires})
        const user_id = localStorage.getItem("user_id");
        const operator_id = localStorage.getItem("operator_id");
        serverApi
          .setUserToken(user_id, operator_id, esia_token, esia_token_expire)
          .then((response) => {
            if(response){
              const user_id = localStorage.getItem('user_id')
              const operator_id = localStorage.getItem('operator_id')
              const body = {
                action: 'checkNoSignedDocs',
                data: {
                  user_id: user_id,
                  operator_id: operator_id
                }
              }
              serverApi.sendPostRequest(body).then((response) => {
                console.log(response)
                if(response.status === 200) {
                  if(response.data.documents.length == 0) navigate('/signed')
                }
            })
          }
            navigate("/doc");
          });
      });
    }
    if (esiaError) {
      alert('К сожалению, возникла проблема при попытке загрузить данные. Пожалуйста, попробуйте повторно перейти по ссылке, которую мы отправили вам ранее на телефон.');
        navigate('/');
    }
  }, []);

  return (
    <div id="main-page-wrapper">
      <div className="justify-content-center" id="sign_loader">
        <div className="">
          <h4 className="text-center loader-text">Получение данных</h4>
          <div className="progress">
            <div className="progress-bar">
              <div className="progress-shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
